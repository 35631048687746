// export const API_BASE = 'https://veloqa.com/dev/api';
// export const API_SUCCESS = 'https://veloqa.com/dev'


// export const API_BASE = 'https://neha.craftandcode.in/api'
// export const API_SUCCESS = 'https://neha.craftandcode.in'

// export const API_BASE = 'https://api.velo.qa/api';
// export const API_SUCCESS = 'https://api.velo.qa'

export const API_BASE = 'https://velo.craftandcode.in/api';
export const API_SUCCESS = 'https://velo.craftandcode.in/'

